import React, { useState, useEffect } from "react";
import "survey-core/defaultV2.min.css";
import { Survey } from "survey-react-ui";
import { Model } from "survey-core";
import { CheckCircle2 } from 'lucide-react';

const getPageQuestionCounts = (survey, pageIndex) => {
  const currentPage = survey.pages[pageIndex];
  let totalQuestionsOnPage = 0;
  let answeredQuestionsOnPage = 0;

  // Recursively count questions in panels and other containers
  const countQuestionsInElement = (element) => {
    if (element.isQuestion && element.isVisible) {
      totalQuestionsOnPage++;
      if (element.value !== undefined && 
          element.value !== null && 
          element.value !== "" && 
          (!Array.isArray(element.value) || element.value.length > 0)) {
        answeredQuestionsOnPage++;
      }
    }
    
    // Check for nested elements (like in panels)
    if (element.elements) {
      element.elements.forEach(nestedElement => {
        countQuestionsInElement(nestedElement);
      });
    }
  };

  // Count questions in all elements on the page
  currentPage.elements.forEach(element => {
    countQuestionsInElement(element);
  });

  return {
    totalQuestionsOnPage,
    answeredQuestionsOnPage,
  };
};


// New Function to Process Elements
const processElement = (element, surveyData) => {
  let questions = [];

  if (element.getType() === "panel" && element.elements) {
    element.elements.forEach((nestedElement) => {
      questions.push(...processElement(nestedElement, surveyData));
    });
  } else if (element.isQuestion) {
    const questionTitle = element.title || element.name;
    const questionName = element.name;
    const answer = surveyData[questionName];

    let formattedAnswer = null;

    if (element.getType() === "checkbox" && Array.isArray(answer)) {
      formattedAnswer = answer.map((selectedValue) => {
        const choice = element.choices.find((c) => c.value === selectedValue);
        return choice ? choice.text : selectedValue;
      }).join(", ");
    } else if (element.getType() === "radiogroup" || element.getType() === "dropdown") {
      const choice = element.choices.find((c) => c.value === answer);
      formattedAnswer = choice ? choice.text : answer;
    } else if (answer !== undefined) {
      formattedAnswer = answer;
    }

    if (formattedAnswer) {
      questions.push({ title: questionTitle, answer: formattedAnswer });
    }
  }

  return questions;
};

// New Function to Format Survey Data
const formatSurveyData = (survey) => {
  console.log("Survey Object:", survey);
  console.log("Survey Data:", survey.data);

  // Collect formatted data using getQuestionByName()
  const formattedData = {
    surveyTitle: survey.title,
    questions: [],
  };

  // Loop through each question in the survey data
  Object.keys(survey.data).forEach((questionName) => {
    const question = survey.getQuestionByName(questionName);

    if (question) {
      formattedData.questions.push({
        questionName: questionName,
        questionTitle: question.title || question.name,
        answer: question.value,
        displayValue: question.displayValue || question.value,
      });
    }
  });

  console.log("Final Formatted Data:", formattedData);
  return formattedData;
};

const SurveyComponent = ({ surveyType, userId, onClose, onProgressUpdate }) => {
  const [surveyModel, setSurveyModel] = useState(null);
  const [error, setError] = useState(null);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [pageProgress, setPageProgress] = useState([]);

  // Calculate total questions and progress across all pages
  const updateTotalProgress = (survey) => {
    let total = 0;
    let answered = 0;
    const newPageProgress = [];

    survey.pages.forEach((page, index) => {
      const { totalQuestionsOnPage, answeredQuestionsOnPage } = getPageQuestionCounts(survey, index);
      total += totalQuestionsOnPage;
      answered += answeredQuestionsOnPage;
      
      newPageProgress[index] = {
        pageName: page.name,
        pageTitle: page.title,
        totalQuestions: totalQuestionsOnPage,
        answeredQuestions: answeredQuestionsOnPage,
        progress: totalQuestionsOnPage > 0 ? 
          (answeredQuestionsOnPage / totalQuestionsOnPage) * 100 : 0
      };
    });

    setTotalQuestions(total);
    setAnsweredQuestions(answered);
    setPageProgress(newPageProgress);
    onProgressUpdate && onProgressUpdate(answered, total);

    return { total, answered, newPageProgress };
  };

  useEffect(() => {
    const initializeSurvey = async () => {
      try {
        const surveyJSON = await import(`../surveys/${surveyType}.json`);
        const survey = new Model(surveyJSON.default);
        
        survey.applyTheme({
          "backgroundImage": "https://api.surveyjs.io/private/Surveys/files?name=2b24cb75-5b10-4e02-a040-e20427bbfbdd",
          "backgroundOpacity": 0.15,
          "backgroundImageAttachment": "scroll",
          "backgroundImageFit": "cover",
          "cssVariables": {
            "--sjs-primary-backcolor": "#2781e8",
            "--sjs-primary-forecolor": "white",
            "--sjs-header-backcolor": "#2781e8",
            "--sjs-header-forecolor": "white",
            "--sjs-questionpanel-backcolor": "rgba(255, 255, 255, 0)"

          }
        });


       //file Upload

       survey.onUploadFiles.add(async (sender, options) => {
        try {
          const formData = new FormData();
          options.files.forEach((file) => {
            formData.append("files", file);
          });
      
          const response = await fetch("http://146.190.123.240/api/files/upload", {
            method: "POST",
            body: formData,
          });
      
          if (!response.ok) {
            throw new Error("File upload failed");
          }
      
          const uploadedFiles = await response.json();
      
          // Transform the response to match the expected SurveyJS format
          const formattedFiles = uploadedFiles.map((file) => ({
            file: { name: file.name },
            content: file.url,
          }));
      
          console.log("Formatted Files for Callback:", formattedFiles);
          options.callback(formattedFiles, null);
        } catch (error) {
          console.error("File upload error:", error);
          options.callback(null, [error.message]);
        }
      });

        // Update counts when questions become visible/hidden
        survey.onVisibleChanged.add((sender, options) => {
          if (options.name === "onVisibleChanged") {
            const progress = updateTotalProgress(sender);
            saveProgress(sender.data, progress);
          }
        });

        // Update counts when page changes
        survey.onCurrentPageChanged.add((sender) => {
          const progress = updateTotalProgress(sender);
          saveProgress(sender.data, progress);
        });

        // Update counts when answers change
        survey.onValueChanged.add(async (sender) => {
          const progress = updateTotalProgress(sender);
          await saveProgress(sender.data, progress);
        });

        // Handle survey completion
        survey.onComplete.add(async (sender) => {
          const formattedData = formatSurveyData(sender);
          console.log("Formatted Survey Data for Submission:", formattedData);
          const progress = updateTotalProgress(sender);
          setIsCompleted(true);
          await submitSurvey(formattedData, progress);
          setTimeout(() => onClose(), 2000);
        });

        await loadSavedProgress(survey);
        setSurveyModel(survey);
        updateTotalProgress(survey);
      } catch (err) {
        console.error("Error initializing survey:", err);
        setError("Error initializing survey");
      }
    };

    initializeSurvey();
  }, [surveyType, userId,]);

  const loadSavedProgress = async (survey) => {
    try {
      const response = await fetch(
        `http://146.190.123.240/api/survey/progress/${userId}?surveyId=${surveyType}`
      );
      const data = await response.json();
  
      if (response.ok && data.data && data.data.length > 0) {
        const surveyProgress = data.data[0]; // Get the first matching progress
        survey.data = surveyProgress.data;
        updateTotalProgress(survey);
        
        if (surveyProgress.isCompleted) {
          setIsCompleted(true);
        }
      }
    } catch (error) {
      console.error("Failed to load progress", error);
    }
  };
  
  const saveProgress = async (data, progress) => {
    try {
      await fetch("http://146.190.123.240/api/survey/progress", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          userId, 
          surveyId: surveyType, // Add surveyId to the request
          data,
          answeredQuestions: progress.answered,
          totalQuestions: progress.total,
          isCompleted: false
        }),
      });
    } catch (error) {
      console.error("Failed to save progress", error);
    }
  };



  const generateSubmissionId = () => {
    // Get current date
    const date = new Date();
    const year = date.getFullYear();
    
    // Generate a random 3-digit number
    const randomNum = Math.floor(Math.random() * 900) + 100; // This will generate a number between 100-999
    
    // Create the ID with format DPDP-2024-XXX
    const submissionId = `DATAINK-${year}-${randomNum}`;
    
    return submissionId;
  };

  const submitSurvey = async (data, progress = null) => {
    try {
      if (!progress) {
        progress = updateTotalProgress(surveyModel);
      }

      const submissionId = generateSubmissionId();

      console.log(submissionId)
  
      const response = await fetch("http://146.190.123.240/api/survey/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          submissionId,
          userId,
          surveyId: surveyType,
          data,
          isCompleted: true,
          answeredQuestions: progress.answered,
          totalQuestions: progress.total,
          pageProgress: progress.newPageProgress,
          startedAt: progress.startedAt || new Date().toISOString(),
        }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to submit survey");
      }
  
      console.log("Survey submitted successfully");
    } catch (error) {
      console.error("Failed to submit survey:", error);
      setError("Failed to submit survey: " + error.message);
    }
  };
  

  const ProgressBar = () => {
    const currentPageIndex = surveyModel?.currentPageNo;
    const currentPageProgress = pageProgress[currentPageIndex] || {};
    const totalProgress = totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;
    
    return (
      <div className="px-6 py-3 bg-gray-50 border-t border-gray-200">
        <div className="flex justify-between items-center mb-2">
          <span className="text-sm text-gray-600">
            Total Progress: {answeredQuestions} of {totalQuestions} questions answered
          </span>
          <span className="text-sm font-medium text-blue-600">
            {Math.round(totalProgress)}%
          </span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
          <div
            className={`h-2 rounded-full transition-all duration-300 ${
              isCompleted ? 'bg-green-600' : 'bg-blue-600'
            }`}
            style={{ width: `${totalProgress}%` }}
          />
        </div>
        {currentPageProgress && (
          <div className="text-xs text-gray-500 text-center">
            Current Page "{currentPageProgress.pageTitle}": 
            {currentPageProgress.answeredQuestions} of {currentPageProgress.totalQuestions} questions
            ({Math.round(currentPageProgress.progress)}%)
          </div>
        )}
      </div>
    );
  };

  const CompletionOverlay = () => (
    <div className="absolute inset-0 bg-white bg-opacity-90 flex flex-col items-center justify-center z-10">
      <CheckCircle2 className="w-16 h-16 text-green-600 mb-4" />
      <h2 className="text-2xl font-semibold text-green-600 mb-2">Survey Completed!</h2>
      <p className="text-gray-600">Thank you for completing the survey</p>
    </div>
  );

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg max-w-md w-full">
          <h2 className="text-xl font-semibold text-red-600 mb-4">Error</h2>
          <p>{error}</p>
          <button
            onClick={onClose}
            className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Close
          </button>
        </div>
      </div>
    );
  }

  if (!surveyModel) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-6 rounded-lg">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600" />
          <p className="mt-4 text-gray-600">Loading survey...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-white z-50 flex flex-col">
  {/* Header */}
  <div className="flex items-center justify-between px-6 py-4 bg-[#2781e8] text-white">
    <h2 className="text-xl font-semibold">{surveyModel.title}</h2>
    <button
      onClick={onClose}
      className="px-4 py-2 bg-white text-blue-600 rounded-md hover:bg-gray-100"
    >
      Back to Surveys
    </button>
  </div>

  {/* Progress Bar */}
  <ProgressBar />

  {/* Main Content */}
  <div className="flex-1 overflow-auto relative">
    {isCompleted && <CompletionOverlay />}
    <Survey model={surveyModel} />
  </div>
</div>

  );
};

export default SurveyComponent;
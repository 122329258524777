import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Shield, Users, FileText, Lock, UserCog, RotateCcw,  Settings, Star, Globe, Calendar, Bell, Heart, BarChart, Clipboard, Folder, CheckCircle } from 'lucide-react'
import SurveyComponent from './SurveyComponent'
import LoadingSpinner from './LoadingSpinner'
import Dashboard from './Dashboard'
import Logo from '../images/logo.jpg'

export default function SurveyHome({ userId }) {
  const [activeSurvey, setActiveSurvey] = useState(null)
  const [surveyProgress, setSurveyProgress] = useState({})
  const [overallProgress, setOverallProgress] = useState(0)
  const [currentView, setCurrentView] = useState('surveys');
  const [roleChecked, setRoleChecked] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showUserDashboard, setShowUserDashboard] = useState(false);
  

  const navigate = useNavigate();

  // Survey definitions with additional total questions property
  const surveys = [
    {
      id: 'Module 1 - Customer Sign Up Process',
      title: 'Module 1 - Customer Sign Up Process',
      description: "This module is intended to cover data collected and received at the time of customer sign-up process either at the time of purchase of products or services or at any time during the process of accessing any part of the website where customers are required to sign up.",
      icon: Shield,
      color: 'green',
      totalQuestions: 10
    },
    {
      id: 'Module 2 - Physical Sign Up Process',
      title: 'Module 2 - Physical Sign Up Process',
      description: 'This module is intended to cover data collected and received from customers physically – either at the time of purchase of products or services, or at any time in the process of accessing any part of your business where customers are required or provided the ability to sign up. This could cover in-store sign ups, for purchase, physical survey forms, or sign up for additional services or other uses',
      icon: Users,
      color: 'green',
      totalQuestions: 8
    },
    {
      id: 'Module 3 - Third-party Vendors and Service Providers',
      title: 'Module 3 - Third-party Vendors and Service Providers',
      description: 'This section is intended to cover information received from third party service providers or vendors on customers or potential customers (regardless of whether such vendor is engaged or contracted specifically for providing such information or for any other purpose)',
      icon: FileText,
      color: 'yellow',
      totalQuestions: 12
    },
    {
      id: 'Module 4 - Social Media, Online Advertising (In-House)',
      title: 'Module 4 - Social Media, Online Advertising (In-House)',
      description: 'This section is intended to cover data that is collected through monitoring, tracking, analysis of data obtained through self-published advertisements, social media activity and other online behavior monitoring activities carried out in-house.',
      icon: Lock,
      color: 'green',
      totalQuestions: 9
    },
    {
      id: 'Module 5 - Third-party Referrals and Aggregators',
      title: 'Module 5 - Third-party Referrals and Aggregators',
      description: 'This section is intended to cover data that is collected through monitoring, tracking, analysis of data obtained from advertisements, social media activity and other online behavior monitoring activities through third party service providers who are specifically engaged to provide this information. For similar activities carried out in-house, please use module 4.',
      icon: UserCog,
      color: 'gray',
      totalQuestions: 7
    },
    {
      id: 'Module 6 - Website Visitors',
      title: 'Module 6 - Website Visitors',
      description: 'This section is intended to cover data that is collected through monitoring, tracking, analysis of data obtained from website visitors that do not convert to customers. For those that do convert to customers, please use Module 1 (in case of sign up).',
      icon: RotateCcw,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 7 - Physical Sales (Own)',
      title: 'Module 7 - Physical Sales (Own)',
      description: 'This section is intended to cover data that is collected at the time of making a sale at a physical location operated by you and manned by personnel from your own entity. For locations operated by third parties or at thir party physical locations, please use next module.',
      icon: Settings,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 8 - Physical Sales (Third Party Premises)',
      title: 'Module 8 - Physical Sales (Third Party Premises)',
      description: 'This section is intended to cover data that is collected through third party sellers of products or services who effect such sales physically.',
      icon: Star,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 9 - Online Sales(Own)',
      title: 'Module 9 - Online Sales(Own)',
      description: 'This section is intended to cover data that at the time of making sales through self owned websites / apps or other digital means.',
      icon: Globe,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 10 - Online Sales (Third Parties)',
      title: 'Module 10 - Online Sales (Third Parties)',
      description: 'This section is intended to cover data that at the time of making sales through third party websites / apps or other digital means.',
      icon: Bell,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 11 - Customer Service (Self)',
      title: 'Module 11 - Customer Service (Self)',
      description: 'This section is intended to cover data is used, collected, accessed or processed during any customer service interaction or service after completion of sale',
      icon: Calendar,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 12 - Customer Service (Third Party Service Providers)',
      title: 'Module 12 - Customer Service (Third Party Service Providers)',
      description: 'This section refers to collection and use of personal information in the process of providing customer service through third parties to individuals who have already purchased a product or service.',
      icon: Heart,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 13 - Employees (This Section Covers Data of Employees of a Company)',
      title: 'Module 13 - Employees (This Section Covers Data of Employees of a Company)',
      description: 'This section is intended to cover data is used, collected, accessed or processed during any customer service interaction or service after completion of sale',
      icon: BarChart,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 14 - Third Party Personnel & Off-Roll Employees',
      title: 'Module 14 - Third Party Personnel & Off-Roll Employees',
      description: '(This section covers data of off roll employees and third party personnel engaged by the Company)',
      icon: Clipboard,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 15 - Minors',
      title: 'Module 15 - Minors',
      description: 'This section covers collection, storage, processing and usage of data of minors by the company at any stage and also includes minor children of employees',
      icon: Folder,
      color: 'green',
      totalQuestions: 6
    },
    {
      id: 'Module 16',
      title: 'Module 16',
      description: '',
      icon: CheckCircle,
      color: 'green',
      totalQuestions: 6
    },
  ]

  useEffect(() => {
     
    setIsLoading(true);
     
    const fetchUserRole = async () => {
      console.log(userId)
      try {
        const response = await fetch(`http://146.190.123.240/api/user/role/${userId}`);
        const data = await response.json();

        console.log(data)
        if (response.ok) {
          setIsAdmin(data.isAdmin);
        }
      } catch (error) {
        console.error("Failed to fetch user role", error);
      } finally {
        setRoleChecked(true);
        setIsLoading(false);
      }
    };



    const fetchSurveyProgress = async () => {
      try {
        const response = await fetch(`http://146.190.123.240/api/survey/progress/${userId}`);
        const data = await response.json();
        if (response.ok) {
          const progress = {};
          data.data.forEach(item => {
            progress[item.surveyId] = {
              answeredQuestions: item.answeredQuestions,
              totalQuestions: item.totalQuestions
            };
          });
          setSurveyProgress(progress);
          calculateOverallProgress(progress);
        }
      } catch (error) {
        console.error("Failed to fetch survey progress", error);
      }
    };


    

    const fetchCompletionStats = async () => {
    try {
      const response = await fetch(`http://146.190.123.240/api/survey/completion-stats/${userId}`);
      const data = await response.json();
      
      if (response.ok && data.success) {
        setOverallProgress(data.data.completionPercentage);
      }
    } catch (error) {
      console.error("Failed to fetch completion stats", error);
    }
  };
  fetchUserRole();
  if (userId) {
    fetchCompletionStats();
  }
    fetchSurveyProgress();
  }, [userId]);


  const handleLogout = async () => {
    try {
      console.log('Calling logout API...');
      const response = await fetch('http://146.190.123.240/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      });
      const data = await response.json();
      console.log('Logout response:', data);
      
      if (response.ok) {
        localStorage.removeItem('userId');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const calculateSurveyProgress = (surveyId) => {
    const progress = surveyProgress[surveyId];
    if (!progress) return 0;

    const percentage = (progress.answeredQuestions / progress.totalQuestions) * 100;
    return Math.round(percentage);
  };

  const calculateOverallProgress = (progress) => {
    const completedSurveys = Object.values(progress).filter(
      survey => survey.answeredQuestions === survey.totalQuestions
    ).length;
    const overallPercentage = (completedSurveys / surveys.length) * 100;
    setOverallProgress(Math.round(overallPercentage));
  };

  const updateSurveyProgress = (surveyId, answeredQuestions, totalQuestions) => {
    const updatedProgress = {
      ...surveyProgress,
      [surveyId]: { answeredQuestions, totalQuestions }
    };
    setSurveyProgress(updatedProgress);
    calculateOverallProgress(updatedProgress);

    // Send updated progress to the server
    fetch("http://146.190.123.240/api/survey/update-progress", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, surveyId, answeredQuestions, totalQuestions }),
    }).catch(error => console.error("Failed to update progress on server", error));
  };

  const getStatusBadge = (surveyId) => {
    const progress = calculateSurveyProgress(surveyId);
    let status, style;

    if (progress === 100) {
      status = 'Complete';
      style = 'bg-green-100 text-green-600';
    } else if (progress > 0) {
      status = 'In Progress';
      style = 'bg-yellow-100 text-yellow-600';
    } else {
      status = 'Not Started';
      style = 'bg-gray-100 text-gray-600';
    }

    return (
      <span className={`text-xs px-2 py-1 rounded-full ${style}`}>
        {status}
      </span>
    );
  };

  const handleSurveyClick = (surveyId) => {
    setActiveSurvey(surveyId);
  };


  // const handleCloseSurvey = () => {
  //   setActiveSurvey(null);
  // };

  // Full-page display for SurveyComponent
  // if (activeSurvey) {
  //   return (
  //     <div className="w-full min-h-screen bg-white">
  //       <SurveyComponent
  //         surveyType={activeSurvey}
  //         userId={userId}
  //         onClose={handleCloseSurvey}
  //         onProgressUpdate={() => {}}
  //       />
  //     </div>
  //   );
  // }
  
  // if (currentView === 'dashboard') {
  //   return <Dashboard onBack={() => setCurrentView('surveys')} OverallProgress={overallProgress} />;
  // }



  const renderDashboardButton = () => {
    if (!roleChecked) return null;

    if (isAdmin) {
      return (
        <button 
          className="px-4 py-2 bg-white text-blue-600 rounded-md hover:bg-gray-100" 
          onClick={() => navigate('/dashboard',{ state: { userId: userId }})}
        >
          Admin Dashboard
        </button>
      );
    }
    
    return (
      <button 
        className="px-4 py-2 bg-white text-blue-600 rounded-md hover:bg-gray-100"
        onClick={() => navigate('/userdashboard', { state: { userId: userId }})}
      >
        User Dashboard
      </button>
    );
  };

  return (
     
    <>

     {isLoading ? (
      <LoadingSpinner />
    ) :(

    <div className="min-h-screen bg-[#f9fafb]">
      {/* Header */}
      <header className="flex items-center justify-between px-6 py-4 bg-[#2781e8]">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 bg-white rounded-lg flex items-center justify-center">
            {/* <Shield className="w-5 h-5 text-blue-600" /> */}

            <img 
              src={Logo} 
              alt="Data Ink Logo" 
              className="w-6 h-6 object-contain"
            />
          </div>
          <h1 className="text-xl font-semibold text-white">Data Ink</h1>
        </div>
        <div className="flex gap-4">
        {renderDashboardButton()}
  
  <button 
  className="px-4 py-2 bg-white text-red-600 rounded-md hover:bg-gray-100"
  onClick={handleLogout}
>
  Logout
</button>
</div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-6 py-8">
        {/* Progress Section */}
        <div className="bg-white rounded-lg p-6 mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-gray-700 font-medium">Overall Progress</h2>
            <span className="text-gray-600">{`${overallProgress}% Complete`}</span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-2">
            <div
              className="bg-blue-600 h-2 rounded-full"
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>

        {/* Survey Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {surveys.map((survey) => (
            <div
              key={survey.id}
              className="bg-white rounded-lg p-6 shadow-md cursor-pointer hover:shadow-lg transition-shadow"
              onClick={() => handleSurveyClick(survey.id)}
            >
              <div className="flex items-start gap-4">
                <div className={`w-10 h-10 rounded-lg bg-${survey.color}-100 flex items-center justify-center`}>
                  <survey.icon className={`w-6 h-6 text-${survey.color}-600`} />
                </div>
                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-2">
                    <h3 className="font-semibold">{survey.title}</h3>
                    {getStatusBadge(survey.id)}
                  </div>
                  <p className="text-sm text-gray-600 mb-4">
                    {survey.description}
                  </p>
                  <div>
                    <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                      <div
                        className="bg-blue-600 h-2 rounded-full"
                        style={{ width: `${calculateSurveyProgress(survey.id)}%` }}
                      />
                    </div>
                    <p className="text-xs text-gray-600 mb-2 text-center">
                      {`${calculateSurveyProgress(survey.id)}% Complete`}
                    </p>
                {calculateSurveyProgress(survey.id) !== 100 && (
                <button
                className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700"
                onClick={(e) => {
                e.stopPropagation()
                handleSurveyClick(survey.id)
                }}
                >
                {calculateSurveyProgress(survey.id) > 0 ? 'Continue Survey' : 'Start Survey'}
                </button>
                )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Survey Modal */}
      {activeSurvey && (
        <SurveyComponent
          surveyType={activeSurvey}
          userId={userId}
          onClose={() => setActiveSurvey(null)}
          onProgressUpdate={(answeredQuestions, totalQuestions) =>
            updateSurveyProgress(activeSurvey, answeredQuestions, totalQuestions)
          }
        />
      )}
    </div>

)}

    </>
  )
}

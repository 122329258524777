import React from 'react'
import {useLocation, useNavigate } from 'react-router-dom' // Add this import
import Logo from '../images/logo.jpg'

const DashBoardHeader = () => {
  const navigate = useNavigate() // Add this hook
  const location = useLocation();
  const userId = location.state?.userId;


  const handleLogout = async () => {
    try {
      console.log('Calling logout API...');
      const response = await fetch('http://146.190.123.240/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      });
      const data = await response.json();
      console.log('Logout response:', data);
      
      if (response.ok) {
        localStorage.removeItem('userId');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div> 
      <header className="bg-blue-600 text-white p-4">
        <div className="container mx-auto flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="w-8 h-8 bg-white rounded-lg flex items-center justify-center">
              {/* <span className="text-blue-600 font-bold">DI</span> */}
              <img 
              src={Logo} 
              alt="Data Ink Logo" 
              className="w-6 h-6 object-contain"
            />
            </div>
            <span className="font-semibold">Data Ink</span>
          </div>
          <div className="flex items-center gap-4"> {/* Increased gap for better spacing */}
            <button 
              onClick={() => navigate('/')} 
              className="px-4 py-2 bg-white text-blue-600 rounded-lg hover:bg-blue-50 transition-colors"
            >
              Back to Survey
            </button>
            <button 
            className="px-4 py-2 bg-white text-red-600 rounded-md hover:bg-gray-100"
            onClick={handleLogout}
          >
            Logout
          </button>
          </div>
        </div>
      </header>
    </div>
  )
}

export default DashBoardHeader
import React, { useState, useEffect } from 'react';
import DashboardHome from './DashboardHome';
import SubmissionsList from './SubmissionsList';
// import GapAnalysis from './GapAnalysis';
import Users from './UserManagement';
import DashBoardHeader from './DashBoardHeader';

export default function Dashboard({overallProgress}) {
  // const [userCount, setUserCount] = useState(0);
  // const [users, setUsers] = useState([]);
  const [activeSection, setActiveSection] = useState('dashboardHome'); // Default section

  // useEffect(() => {
  //   fetch('http://localhost:5000/api/users/count')
  //     .then(res => res.json())
  //     .then(data => {
  //       setUserCount(data.total);
  //       setUsers(data.users);
  //     })
  //     .catch(err => console.error('Error:', err));
  // }, []);


  return (
    <>
    <DashBoardHeader/>
    <div className="flex h-screen bg-gray-50">
     
      {/* Sidebar */}
      <aside className="w-64 bg-white border-r">
        <nav className="p-6 space-y-2">
          <div
            className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
              activeSection === 'dashboardHome' ? 'text-blue-600 bg-blue-50' : 'text-gray-600'
            }`}
            onClick={() => setActiveSection('dashboardHome')}
          >
            <span className="font-medium">Dashboard</span>
          </div>
          <div
            className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
              activeSection === 'submissions' ? 'text-blue-600 bg-blue-50' : 'text-gray-600'
            }`}
            onClick={() => setActiveSection('submissions')}
          >
            <span>Submissions</span>
          </div>
          {/* <div
            className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
              activeSection === 'gap' ? 'text-blue-600 bg-blue-50' : 'text-gray-600'
            }`}
            onClick={() => setActiveSection('gap')}
          >
            <span>Gap Analysis</span>
          </div> */}
          <div
            className={`flex items-center space-x-2 p-2 rounded cursor-pointer ${
              activeSection === 'users' ? 'text-blue-600 bg-blue-50' : 'text-gray-600'
            }`}
            onClick={() => setActiveSection('users')}
          >
            <span>Users</span>
          </div>
        </nav>
      </aside>

      {/* Main Content */}
      
      <main className="flex-1 p-8">
       
        {activeSection === 'dashboardHome' && <DashboardHome overallProgress={overallProgress} />}
        {activeSection === 'submissions' && <SubmissionsList />}
        {/* {activeSection === 'gap' && <GapAnalysis />} */}
        {activeSection === 'users' && <Users />}
      </main>
    </div>
    </>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const CookieConsent = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [formData, setFormData] = useState({
    websiteUrl: '',
    websiteName: '',
    entityType: '',
    country: '',
    state: '',
    cookieNotice: '',
    analyticsTools: '',
    advertisingPlatforms: '',
    socialMedia: '',
    contactMethods: {
      email: false,
      form: false,
      phone: false,
      address: false
    },
    emailAddress: '',
    language: 'English',
    preferences: {
      necessary: true,
      functional: false,
      analytics: false
    }
  });

  const location = useLocation();
  const userId = location.state?.userId;

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        const response = await axios.get(`/api/cookie-consent/${userId}`);
        if (response.data.preferences) {
          setFormData(prev => ({
            ...prev,
            preferences: response.data.preferences
          }));
          setIsVisible(false);
        }
      } catch (error) {
        console.error('Error fetching cookie preferences:', error);
      }
    };

    if (userId) {
      fetchPreferences();
    }
  }, [userId]);
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === 'checkbox') {
      if (name in formData.contactMethods) {
        setFormData(prev => ({
          ...prev,
          contactMethods: {
            ...prev.contactMethods,
            [name]: checked
          }
        }));
      } else if (['necessary', 'functional', 'analytics'].includes(name)) {
        setFormData(prev => ({
          ...prev,
          preferences: {
            ...prev.preferences,
            [name]: checked
          }
        }));
      }
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, 3));
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 1));
  };

  const handleAcceptAll = async () => {
    try {
      await axios.post('http://146.190.123.240/api/cookie-consent', {
        userId,
        ...formData,
        preferences: {
          necessary: true,
          functional: true,
          analytics: true
        }
      });
      setFormData(prev => ({
        ...prev,
        preferences: {
          necessary: true,
          functional: true,
          analytics: true
        }
      }));
      setIsVisible(false);
    } catch (error) {
      console.error('Error saving cookie preferences:', error);
    }
  };

  const handleSavePreferences = async () => {
    try {
      await axios.post('http://146.190.123.240/api/cookie-consent', {
        userId,
        ...formData
      });
      setIsVisible(false);
    } catch (error) {
      console.error('Error saving cookie preferences:', error);
    }
  };

  const handleGenerate = async () => {
    try {
      const response = await axios.post('http://146.190.123.240/api/generate-policy', {
        userId,
        ...formData
      });
      console.log('Policy generated:', response.data);
    } catch (error) {
      console.error('Error generating policy:', error);
    }
  };

  // Progress Steps Component
  const ProgressSteps = () => (
    <div className="flex justify-center mb-8">
      <div className="flex items-center">
        {[1, 2, 3].map((step, index) => (
          <React.Fragment key={step}>
            <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
              currentStep >= step ? 'bg-blue-600 text-white' : 'bg-gray-200'
            }`}>
              {step}
            </div>
            {index < 2 && (
              <div className={`h-1 w-16 ${
                currentStep > step ? 'bg-blue-600' : 'bg-gray-200'
              }`} />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );

  // Step 1: Website Details
  const Step1 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          What is your website URL?
        </label>
        <input
          type="text"
          name="websiteUrl"
          value={formData.websiteUrl}
          onChange={handleInputChange}
          placeholder="http://www.mysite.com"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        />
        <p className="mt-1 text-sm text-gray-500">e.g. http://www.mysite.com</p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          What is your website name?
        </label>
        <input
          type="text"
          name="websiteName"
          value={formData.websiteName}
          onChange={handleInputChange}
          placeholder="My Site"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        />
        <p className="mt-1 text-sm text-gray-500">e.g. My Site</p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Entity type
        </label>
        <div className="space-y-2">
          <div className="flex items-center">
            <input
              type="radio"
              name="entityType"
              value="business"
              checked={formData.entityType === 'business'}
              onChange={handleInputChange}
              className="h-4 w-4"
            />
            <label className="ml-2 text-sm text-gray-700">
              I'm a Business
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              name="entityType"
              value="individual"
              checked={formData.entityType === 'individual'}
              onChange={handleInputChange}
              className="h-4 w-4"
            />
            <label className="ml-2 text-sm text-gray-700">
              I'm an Individual
            </label>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Enter the country
          </label>
          <select
            name="country"
            value={formData.country}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          >
            <option value="">Select country</option>
            <option value="us">United States</option>
            <option value="uk">United Kingdom</option>
            <option value="ca">Canada</option>
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Enter the state
          </label>
          <select
            name="state"
            value={formData.state}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
          >
            <option value="">Select state</option>
            <option value="ny">New York</option>
            <option value="ca">California</option>
            <option value="tx">Texas</option>
          </select>
        </div>
      </div>
    </div>
  );

  // Step 2: Cookie Settings and Preferences
  const Step2 = () => (
    <div className="space-y-6">
      <div>
        <div className="border-b pb-4 mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Do you have a Cookie Notice banner or dialog on your website?
          </label>
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="radio"
                name="cookieNotice"
                value="yes"
                checked={formData.cookieNotice === 'yes'}
                onChange={handleInputChange}
                className="h-4 w-4"
              />
              <label className="ml-2 text-sm text-gray-700">
                Yes, we show a Cookie Notice
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="cookieNotice"
                value="no"
                checked={formData.cookieNotice === 'no'}
                onChange={handleInputChange}
                className="h-4 w-4"
              />
              <label className="ml-2 text-sm text-gray-700">No</label>
            </div>
          </div>
        </div>

        <div className="border-b pb-4 mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Do you use analytics tools?
          </label>
          <div className="space-y-2">
            <div className="flex items-center">
              <input
                type="radio"
                name="analyticsTools"
                value="yes"
                checked={formData.analyticsTools === 'yes'}
                onChange={handleInputChange}
                className="h-4 w-4"
              />
              <label className="ml-2 text-sm text-gray-700">
                Yes, we use various analytics tools
              </label>
            </div>
            <div className="flex items-center">
              <input
                type="radio"
                name="analyticsTools"
                value="no"
                checked={formData.analyticsTools === 'no'}
                onChange={handleInputChange}
                className="h-4 w-4"
              />
              <label className="ml-2 text-sm text-gray-700">No</label>
            </div>
          </div>
        </div>

        <h3 className="text-lg font-semibold mb-4">Cookie Preferences</h3>
        <p className="mb-6 text-gray-600">
          We use cookies to enhance your browsing experience and analyze our traffic.
        </p>

        <div className="space-y-4 mb-6">
          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <label className="font-medium">Necessary</label>
              <p className="text-sm text-gray-500">Required for basic functionality</p>
            </div>
            <input
              type="checkbox"
              name="necessary"
              checked={formData.preferences.necessary}
              disabled
              className="h-4 w-4"
            />
          </div>

          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <label className="font-medium">Functional</label>
              <p className="text-sm text-gray-500">For enhanced features</p>
            </div>
            <input
              type="checkbox"
              name="functional"
              checked={formData.preferences.functional}
              onChange={handleInputChange}
              className="h-4 w-4"
            />
          </div>

          <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
            <div>
              <label className="font-medium">Analytics</label>
              <p className="text-sm text-gray-500">Help us improve our website</p>
            </div>
            <input
              type="checkbox"
              name="analytics"
              checked={formData.preferences.analytics}
              onChange={handleInputChange}
              className="h-4 w-4"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            How can users contact you for questions about your Cookie Policy?
          </label>
          <div className="space-y-2">
            {Object.entries({
              email: 'By email',
              form: 'By contact form',
              phone: 'By phone',
              address: 'By mail'
            }).map(([key, label]) => (
              <div key={key} className="flex items-center">
                <input
                  type="checkbox"
                  name={key}
                  checked={formData.contactMethods[key]}
                  onChange={handleInputChange}
                  className="h-4 w-4"
                />
                <label className="ml-2 text-sm text-gray-700">{label}</label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  // Step 3: Final Step
  const Step3 = () => (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Your e-mail address to receive the Cookie Policy
        </label>
        <input
          type="email"
          name="emailAddress"
          value={formData.emailAddress}
          onChange={handleInputChange}
          placeholder="Enter your email"
          className="w-full px-3 py-2 border border-gray-300 rounded-lg"
        />
        <p className="mt-1 text-sm text-gray-500">
          You will receive the Cookie Policy to this email address
        </p>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Choose additional languages
        </label>
        <div className="inline-flex items-center space-x-2 border rounded-md px-4 py-2">
          <img src="/api/placeholder/24/24" alt="US Flag" className="w-6 h-6" />
          <span>English</span>
        </div>
      </div>

      <div className="bg-gray-50 p-4 rounded-md text-center">
        <p>Price for this Cookie Policy: <span className="font-bold">0 USD</span> (It's free)</p>
      </div>

      <p className="text-sm text-gray-500 text-center">
        By clicking "Generate", you agree to our Terms of Use, Privacy Policy and Disclaimer.
      </p>
    </div>
  );

  if (!isVisible) return null;

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <div className="max-w-2xl mx-auto">
        <div className="bg-white rounded-lg shadow p-6">
          <h1 className="text-2xl font-bold text-center mb-8">Cookie Policy Generator</h1>
          <ProgressSteps />
          
          {currentStep === 1 && <Step1 />}
          {currentStep === 2 && <Step2 />}
          {currentStep === 3 && <Step3 />}

          <div className="mt-8 flex justify-between">
            {currentStep > 1 && (
              <button
                onClick={handleBack}
                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
              >
                Go Back
              </button>
            )}
            
            {currentStep < 3 ? (
              <button
                onClick={handleNext}
                className="ml-auto px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                Next Step
              </button>
            ) : (
              <div className="flex space-x-4 ml-auto">
                <button
                  onClick={handleAcceptAll}
                  className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
                >
                  Accept All
                </button>
                <button
                  onClick={handleSavePreferences}
                  className="px-6 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300"
                >
                  Save Preferences
                </button>
                <button
                  onClick={handleGenerate}
                  className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                >
                  Generate
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
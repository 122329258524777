import React from 'react';
import { Loader2 } from 'lucide-react';


const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 bg-[#f9fafb] flex flex-col items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center space-y-4">
        <Loader2 className="w-12 h-12 text-blue-600 animate-spin" />
        <p className="text-gray-600 font-medium">Loading...</p>
        <p className="text-sm text-gray-500">Please wait while we fetch your data</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
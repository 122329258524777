import React, { useState, useEffect } from 'react';
import { FileText, Printer, Download, AlertCircle } from 'lucide-react';
import { useLocation,  useNavigate } from 'react-router-dom';
import CookieConsent from './CookieConsent';

const UserDashboard = () => {
  const [reports, setReports] = useState([]);
  const navigate = useNavigate() 
  const [userInfo, setUserInfo] = useState(null);
  const [allReports,setAllReports]=useState([])
  const [showCookieSettings, setShowCookieSettings] = useState(false);


  const location = useLocation();
  const userId = location.state?.userId;


  useEffect(() => {


    const fetchUserInfo = async () => {
      try {
        const response = await fetch(`http://146.190.123.240/api/user/${userId}`);
        const data = await response.json();
        if (data.success) {
          setUserInfo(data.user);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    const fetchReports = async () => {
      try {
        console.log("Fetching reports for userId:", userId);
        const response = await fetch(`http://146.190.123.240/api/gap-analysis/user/${userId}`);
        const data = await response.json();

        console.log(data)
        
        if (data.success) {
          console.log('Gap Analysis Data:', data.files);
          setReports(data.files);
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };

    const fetchAllReports = async () => {
      try {
        console.log("Fetching reports for userId:", userId);
        const response = await fetch(`http://146.190.123.240/api/all-reports`);
        const data = await response.json();

        console.log(data)
        
        if (data.success) {
          console.log('Gap Analysis Data:', data.reports);
          setAllReports(data.reports);
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
      }
    };




    fetchReports();
    fetchAllReports()
    fetchUserInfo();
  }, [userId]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };


  const calculateNextAuditDate = (reportDate) => {
    const date = new Date(reportDate);
    date.setMonth(date.getMonth() + 12);
    return formatDate(date);
  };



  const handleDownload = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const pdfBlob = new Blob([blob], { type: 'application/pdf' });
      const downloadUrl = window.URL.createObjectURL(pdfBlob);
      
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename.endsWith('.pdf') ? filename : `${filename}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };


  const handleDownloadAll = async () => {
    try {
      for (const report of reports) {
        await handleDownload(report.url, report.name);
      }
    } catch (error) {
      console.error('Error downloading all files:', error);
    }
  };





  const handleLogout = async () => {
    try {
      console.log('Calling logout API...');
      const response = await fetch('http://146.190.123.240/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userId })
      });
      const data = await response.json();
      console.log('Logout response:', data);
      
      if (response.ok) {
        localStorage.removeItem('userId');
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
    <header className="bg-gradient-to-r from-indigo-600 to-blue-500">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <FileText className="h-8 w-8 text-white" />
            </div>
            <div className="ml-4 text-xl font-bold text-white">GAP Analysis Reports</div>
          </div>
          <div className='flex items-center gap-4'>
          <button
            onClick={() => navigate('/')}
            className="px-4 py-2 text-sm font-medium text-indigo-600 bg-white rounded-lg hover:bg-indigo-50"
          >
            Back to Surveys
          </button>

          {/* <button
          onClick={() => setShowCookieSettings(true)}
          className="px-4 py-2 text-sm font-medium text-gray-600 bg-white rounded-lg hover:bg-gray-50 border border-gray-200"
           >
          Cookie Settings
          </button> */}

          <button 
          className="px-4 py-2 bg-white text-red-600 rounded-md hover:bg-gray-100"
          onClick={handleLogout}
        >
          Logout
        </button>

          </div>
        </div>
      </div>
    </header>
    


      {/* User Info Section */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            {userInfo && (
              <div className="mb-4 md:mb-0">
                <h2 className="text-lg font-semibold text-gray-900">
                  Welcome, {userInfo.firstName} {userInfo.lastName}
                </h2>
                <p className="text-sm text-gray-600">{userInfo.email}</p>
              </div>
            )}
            {reports.length > 0 && (
              <div className="flex items-center bg-indigo-50 rounded-lg px-4 py-2">
                <div>
                  <p className="text-sm font-medium text-indigo-900">Next Audit Due:</p>
                  <p className="text-sm text-indigo-600">
                    {calculateNextAuditDate(reports[0].uploadedAt)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Alert for 30-day deletion policy */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
        <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Reports will be automatically deleted after 30 days from submission. 
                {reports.length > 0 && ` Latest report will be deleted on ${(() => {
                  const date = new Date(reports[0].uploadedAt);
                  date.setDate(date.getDate() + 30);
                  return formatDate(date);
                })()}`}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Available Reports</h2>
          </div>

          <div className="divide-y divide-gray-200">
            {reports.map((report, index) => (
              <div key={index} className="px-6 py-4 flex items-center justify-between hover:bg-gray-50">
                <div className="flex items-center">
                  <div className="p-2 bg-red-50 rounded-lg">
                    <FileText className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-900">{report.name}</h3>
                    <p className="text-sm text-gray-500">Uploaded on {formatDate(report.uploadedAt)}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  {/* <button className="text-sm text-indigo-600 hover:text-indigo-900 flex items-center">
                    <Printer className="h-4 w-4 mr-1" />
                    Print
                  </button> */}
                  <button 
                    onClick={() => handleDownload(report.url, report.name)}
                    className="text-sm text-indigo-600 hover:text-indigo-900 flex items-center"
                  >
                    <Download className="h-4 w-4 mr-1" />
                    Download PDF
                  </button>
                </div>
              </div>
            ))}
          </div>

          {reports.length > 0 && (
            <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
              <div className="flex justify-end">
                <button className="flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700">
                  <Download className="h-4 w-4 mr-2" />
                  Download All Reports
                </button>
              </div>
            </div>
          )}
        </div>

        
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <div className="px-6 py-4 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">Remedy Reports</h2>
          </div>

          <div className="divide-y divide-gray-200">
            {allReports.map((report, index) => (
              <div
                key={index}
                className="px-6 py-4 flex items-center justify-between hover:bg-gray-50"
              >
                <div className="flex items-center">
                  <div className="p-2 bg-green-50 rounded-lg">
                    <FileText className="h-6 w-6 text-green-600" />
                  </div>
                  <div className="ml-4">
                    <h3 className="text-sm font-medium text-gray-900">{report.name}</h3>
                    <p className="text-sm text-gray-500">Uploaded on {formatDate(report.uploadedAt)}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <a
                    href={`http://146.190.123.240/api/reports/download/${report._id}`}
                    download
                    className="text-indigo-600 hover:text-indigo-900 p-2 rounded-full hover:bg-indigo-50"
                  >
                    <Download className="w-5 h-5" />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>


      {showCookieSettings && (
  <div className="fixed inset-0 bg-black bg-opacity-50 z-50">
    <div className="relative">
      <CookieConsent 
        userId={userId} 
        onClose={() => setShowCookieSettings(false)}
      />
    </div>
  </div>
)}
    </div>
  );
};

export default UserDashboard;
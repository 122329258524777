import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";

export default function EmailConfirmation() {
  const [status, setStatus] = useState("verifying");
  const [message, setMessage] = useState("");
  const { token } = useParams();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(
          `http://146.190.123.240/api/verify-email/${token}`
        );
        if (response.data.success) {
          setStatus("success");
          setMessage(response.data.message);
        } else {
          setStatus("error");
          setMessage(response.data.message);
        }
      } catch (error) {
        setStatus("error");
        setMessage(
          error.response?.data?.message ||
            "An error occurred during email verification."
        );
      }
    };

    verifyEmail();
  }, [token]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">
          Email Verification
        </h2>
        {status === "verifying" && (
          <p className="text-center text-gray-600">Verifying your email...</p>
        )}
        {status === "success" && (
          <div className="text-center">
            <p className="text-green-600 mb-4">{message}</p>
            <Link
              to="/"
              className="inline-block bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
            >
              Go to Login
            </Link>
          </div>
        )}
        {status === "error" && (
          <div className="text-center">
            <p className="text-red-600 mb-4">{message}</p>
            <Link
              to="/"
              className="inline-block bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
            >
              Go to Home
            </Link>
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";

import SubmissionDetails from "./SubmissionDetails"

export default function SubmissionsList() {
  const [users, setUsers] = useState([]);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   const [selectedUser, setSelectedUser] = useState(null);
   const [surveyId, setSurveyId] = useState(null);
 




    // Function to handle the View button click
    const handleViewClick = (user,survey) => {
      setSelectedUser(user);
      setSurveyId(survey)

     

    
    };
  
    // Function to handle going back to the submissions list
    const handleBackToList = () => {
      setSelectedUser(null);
    };



 
    

    useEffect(() => {
      const fetchUsersWithResults = async () => {
        try {
          const response = await fetch("http://146.190.123.240/api/admin/users-with-survey-results");
    
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
    
          const data = await response.json();
          
          console.log(data)

          if (data.success) {
            setUsers(data.data);
          } else {
            setError(data.message || "Failed to fetch users with survey results");
          }
        } catch (err) {
          setError("Error fetching users with survey results. Please try again later.");
          console.error("Fetch Error:", err);
        } finally {
          setLoading(false);
        }
      };
    
      fetchUsersWithResults();
    }, []);
    

  function getInitials(firstname, lastname) {
    if (!firstname && !lastname) return '';
    const firstInitial = firstname?.trim()?.[0]?.toUpperCase() || '';
    const lastInitial = lastname?.trim()?.[0]?.toUpperCase() || '';
    return firstInitial + lastInitial;
  }

  return  selectedUser ?  (<SubmissionDetails user={selectedUser} survey={surveyId} onBack={handleBackToList} />) :(

    <div className="p-8">
      {/* <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-semibold mb-2">Survey Submissions</h1>
          <p className="text-gray-600">View and manage all DPDP survey submissions</p>
        </div>
        <button className="bg-indigo-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-indigo-700">
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
            />
          </svg>
          Export Data
        </button>
      </div> */}

      <div className="bg-white rounded-lg shadow">
        {/* <div className="p-4 border-b flex flex-wrap gap-4">
          <div className="flex-1 min-w-[200px]">
            <input
              type="text"
              placeholder="Search users..."
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="w-[150px]">
            <input
              type="date"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <div className="w-[200px]">
            <select className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500">
              <option>All Statuses</option>
              <option>Draft</option>
              <option>Complete</option>
            </select>
          </div>
          <button className="text-indigo-600 hover:text-indigo-800">
            Clear Filters
          </button>
        </div> */}

        {loading ? (
          <div className="p-6 text-center">Loading...</div>
        ) : (
          <table className="w-full">
            <thead>
              <tr className="text-left text-sm text-gray-500 border-b">
                <th className="px-6 py-3">USER</th>
                <th className="px-6 py-3">MODULE</th>
                <th className="px-6 py-3">STATUS</th>
                <th className="px-6 py-3">PROGRESS</th>
                <th className="px-6 py-3">SUBMISSION DATE</th>
                <th className="px-6 py-3">ACTIONS</th>
              </tr>
            </thead>
            <tbody>
  {users.map((user, userIndex) =>
    user.surveys && user.surveys.length > 0 ? (
      user.surveys.map((survey, surveyIndex) => {
        // Calculate progress percentage
        const progress =
          survey.totalQuestions && survey.answeredQuestions
            ? Math.round((survey.answeredQuestions / survey.totalQuestions) * 100)
            : 0;

        // Determine the survey status based on progress
        const status = progress === 100 ? "Complete" : "Draft";

        return (
          <tr key={`${userIndex}-${surveyIndex}`} className="border-b">
            <td className="px-6 py-4">
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center font-medium">
                  {getInitials(user.firstName, user.lastName)}
                </div>
                <div className="ml-3">
                  <div className="font-medium">
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="text-sm text-gray-500">{user.email}</div>
                </div>
              </div>
            </td>
            <td className="px-6 py-4">
                          <span className="text-sm text-gray-700">
                          {survey.surveyId|| "N/A"}
                          </span>
                        </td>
            <td className="px-6 py-4">
              <span
                className={`${
                  status === "Complete"
                    ? "bg-green-100 text-green-800"
                    : "bg-yellow-100 text-yellow-800"
                } text-xs px-2 py-1 rounded-full`}
              >
                {status}
              </span>
            </td>
            <td className="px-6 py-4">
              <div className="w-full max-w-[200px]">
                <div className="flex justify-between text-sm mb-1">
                  <span>Progress</span>
                  <span>{progress}%</span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2">
                  <div
                    className={`${
                      progress === 100 ? "bg-green-600" : "bg-indigo-600"
                    } h-2 rounded-full`}
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>
            </td>
            <td className="px-6 py-4 text-sm text-gray-500">
            {survey.completedAt ? new Date(survey.completedAt).toISOString().split('T')[0] : "-"}
            </td>
            <td className="px-6 py-4">
              <div className="flex gap-4">
                <button
                  className="text-indigo-600 hover:text-indigo-800"
                  onClick={() => handleViewClick(user, survey._id)}
                >
                  View
                </button>
                {/* <button className="text-indigo-600 hover:text-indigo-800">
                  Download
                </button> */}
              </div>
            </td>
          </tr>
        );
      })
    ) : (
      // <tr key={`no-surveys-${userIndex}`} className="border-b">
      //   <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
      //     No survey results for {user.firstName} {user.lastName}
      //   </td>
      // </tr>
      ""
    )
  )}
</tbody>
          </table>
        )}

        {!loading && users.length === 0 && (
          // <div className="p-6 text-center text-gray-500">
          //   No submissions found.
          // </div>
          ""
        )}

        {/* <div className="px-6 py-4 border-t flex items-center justify-between">
          <div className="text-sm text-gray-500">
            Showing {users.length} submission(s)
          </div>
        </div> */}
      </div>
    
    
     

    </div>
  );
}

import React, { useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import Register from "./components/Register";
import Survey from "./components/SurveyComponent";
import EmailConfirmation from "./components/EmailConfirm";
import VerificationForm from "./components/VerificationForm";
import SurveyHome from "./components/SurveyHome";
import Dashboard from "./components/Dashboard";
import SubmissionsList from "./components/SubmissionsList";
import SubmissionDetails from "./components/SubmissionDetails";
import UserManagement from "./components/UserManagement";
import DashboardHome from "./components/DashboardHome";
import UserDashboard from "./components/UserDashboard";
// import CookieConsent from './components/CookieConsent';
import Logo from "./images/Loginlogo.jpg"


export default function App() {
  const [step, setStep] = useState("login");
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const handleVerify = async (verificationCode) => {
    try {
      const response = await axios.post("http://146.190.123.240/api/verify", {
        userId,
        verificationCode,
      });
      if (response.data.success) {
        if (isAdmin) {
          console.log("Admin user verified, redirecting to admin dashboard");
          setStep("admin");
        } else {
          console.log("Non-admin user verified, redirecting to survey home");
          setStep("surveyHome");
        }
        setMessage("Login successful!");
      } else {
        setMessage(response.data.message || "Verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Verification error:", error);
      setMessage(error.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  const handleLogout = () => {
    setUserId("");
    setIsAdmin(false);
    setStep("login");
    setMessage("Logged out successfully.");
  };

  console.log("Current step:", step);
  console.log("Is admin:", isAdmin);

  return (
    <div className="print-disable">
    <Router>
      <div className={step === 'surveyHome' ? 'w-full' : 'min-h-screen flex items-center justify-center bg-gray-100'}>
        <div className={step === 'surveyHome' ? 'w-full' : 'bg-white p-8 rounded-lg shadow-md w-full max-w-md'}>
          {step !== 'surveyHome' && <div className="flex flex-col items-center justify-center mb-6">
        <div className="mb-2">
        <img 
        src={Logo} 
        alt="Data Ink Logo" 
        className="w-12 h-12"
        />
        </div>
         <h2 className="text-2xl font-bold text-center">Data Ink</h2>
        </div>}
          <Routes>
            <Route path="/verify-email/:token" element={<EmailConfirmation />} />
            <Route
              path="/"
              element={
                <>
                  {step === "register" && (
                    <Register setStep={setStep} setMessage={setMessage} />
                  )}
                  {step === "login" && (
                    <Login
                      setStep={setStep}
                      setUserId={setUserId}
                      setIsAdmin={setIsAdmin}
                      setMessage={setMessage}
                    />
                  )}
                  {/* {step === "verify" && (
                    <VerificationForm handleVerify={handleVerify} />
                  )} */}
                  {step === "surveyHome" && !isAdmin && (
                    <SurveyHome userId={userId} handleLogout={handleLogout} />
                  )}
                  {step === "survey" && !isAdmin && (
                    <Survey userId={userId} handleLogout={handleLogout} />
                  )}
                  {message && (
                    <p className={`mt-4 text-sm text-center ${
                      message.includes("successful") ? "text-green-600" : "text-red-600"
                    }`}>
                      {message}
                    </p>
                  )}
                </>
              }
            />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/userdashboard" element={<UserDashboard />} />
            {/* <Route path="/submissions" element={<SubmissionsList />} />
            <Route path="/submissionsDetails" element={<SubmissionDetails />} />
            <Route path="/users" element={<UserManagement/>} /> */}
            {/* <Route path="/userdashboard/cookie-settings" element={<CookieConsent />} /> */}
         
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </Router>
    </div>
  );
}


import React, { useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { FileText, Download, Archive } from 'lucide-react';
import axios from "axios";
import JSZip from 'jszip';


export default function SubmissionDetails({ user, survey, onBack }) {

  console.log(survey)

  console.log("survey is",survey)
  const [surveyData, setSurveyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('survey');
  const [latestGapAnalysisFile, setLatestGapAnalysisFile] = useState(null);
  const [versionHistory, setVersionHistory] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [reports, setReports] = useState([]);

  

  // Function to get user initials
  function getInitials(firstname, lastname) {
    if (!firstname && !lastname) return '';
    const firstInitial = firstname?.trim()?.[0]?.toUpperCase() || '';
    const lastInitial = lastname?.trim()?.[0]?.toUpperCase() || '';
    return firstInitial + lastInitial;
  }





  // Fetch user's survey data when the component mounts
  useEffect(() => {

    console.log(user._id)
    console.log("user surveyId is",survey)
    const fetchSurveyData = async () => {
      try {
        const response = await fetch(`http://146.190.123.240/api/admin/survey-results/${survey}`);
    
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log(data.results[0])
    
        if (data.success && data.results?.length > 0) {
          setSurveyData(data.results[0]); // Assuming you want the first survey result
          console.log("Updated surveyData:", surveyData);
        } else {
          setError(data.message || "Failed to fetch survey results");
        }
      } catch (err) {
        setError("Error fetching survey results. Please try again later.");
        console.error("Fetch Error:", err);
      } finally {
        setLoading(false);
      }
    };
    

    fetchSurveyData();
    
    
  }, []);


  useEffect(() => {
    if (surveyData?._id) {
      fetchReports();
    }
  }, [surveyData]);



  // Add this function to handle report uploads
const handleReportUpload = async (e) => {
  try {
    const files = e.target.files;
    if (!files?.length) {
      alert("Please select files to upload.");
      return;
    }

    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append("files", file));
    formData.append("surveyId", surveyData._id );
    formData.append("UserId", surveyData.userId);
    formData.append("surveyTitle", surveyData.surveyId);

    const uploadResponse = await fetch("http://146.190.123.240/api/reports/upload", {
      method: 'POST',
      body: formData
    });

    if (!uploadResponse.ok) throw new Error('Upload failed');
    await fetchReports();
  } catch (error) {
    console.error("Error uploading report:", error);
    alert("Report upload failed. Please try again.");
  }
};



const fetchGapAnalysisFiles = async () => {
  try {
    const response = await axios.get(`http://146.190.123.240/api/gap-analysis/${survey._id}`);
    if (response.data.success && response.data.files.length > 0) {
      // Sort files by uploadedAt date in descending order (most recent first)
      const sortedFiles = response.data.files.sort(
        (a, b) => new Date(b.uploadedAt) - new Date(a.uploadedAt)
      );

      // Set the latest file as the first item in the sorted array
      setLatestGapAnalysisFile(sortedFiles[0]);

      // Exclude the latest file from the version history
      setVersionHistory(sortedFiles.slice(1));
      console.log(latestGapAnalysisFile)
      console.log(versionHistory)
    } else {
      setLatestGapAnalysisFile(null);
      setVersionHistory([]);
    }
  } catch (error) {
    console.error("Error fetching Gap Analysis files:", error);
    setLatestGapAnalysisFile(null);
    setVersionHistory([]);
  }
};

    

    
  
const handleGapAnalysisUpload = async (e) => {
  try {
    const files = e.target.files;
    if (!files?.length) {
      alert("Please select files to upload.");
      return;
    }

    // CHANGED: Removed version check and set initial version to 1.0
    const formData = new FormData();
    Array.from(files).forEach((file) => formData.append("files", file));
    formData.append("version", "1.0"); // CHANGED: Set fixed initial version
    formData.append("surveyId", surveyData._id);
    formData.append("UserId", surveyData.userId);
    formData.append("surveyTitle", surveyData.surveyId);

    const uploadResponse = await fetch("http://146.190.123.240/api/gap-analysis/upload", {
      method: 'POST',
      body: formData
    });

    if (!uploadResponse.ok) throw new Error('Upload failed');
    await fetchGapAnalysisFiles();
    
  } catch (error) {
    console.error("Error uploading files:", error);
    alert("File upload failed. Please try again.");
  }
};

  const formatAnswer = (answer) => {
    if (!answer) return 'No answer provided';
     
    // console.log("Raw answer:", answer);
    // console.log("Answer type:", typeof answer);

    try {
      // If answer is already a string, return it
      if (typeof answer === 'string') return answer;
      
      // Parse string JSON if needed
      const parsed = typeof answer === 'string' ? JSON.parse(answer) : answer;

        // Handle when answer is directly an array containing file object
        if (Array.isArray(answer) && answer.length > 0 && answer[0]?.name) {
          return answer[0].name;
        }


       // Handle case where we have an answer array with file info
       if (parsed.answer && Array.isArray(parsed.answer) && parsed.answer[0]?.name) {
        return parsed.answer.map(file => file.name).join(", ");
      }
      
      // Handle case where we have a displayValue array with file info
      if (parsed.displayValue && Array.isArray(parsed.displayValue) && parsed.displayValue[0]?.name) {
        return parsed.displayValue.map(file => file.name).join(", ");
      }
      
      // Handle arrays
      if (Array.isArray(parsed)) {
        return parsed.join(", ");
      }
     // If the answer is a displayValue array containing file objects
    // if (parsed?.displayValue && Array.isArray(parsed.displayValue)) {
    //  return parsed.displayValue.map(file => file.name).join(", ");
    // }

    // Handle single file object
    if (typeof parsed === 'object' && parsed !== null && parsed.name) {
     return parsed.name;
     }
      
      // Handle objects
      if (typeof parsed === 'object') {
        // If it's a nested structure like {"Column1": "value"}
        if (Object.keys(parsed).length === 1) {
          const firstValue = Object.values(parsed)[0];
          if (typeof firstValue === 'object') {
            return Object.values(firstValue)[0] || 'No answer provided';
          }
          return firstValue;
        }
        
        // For flat objects, join key-value pairs
        return Object.entries(parsed)
          .map(([key, value]) => {
            // Handle nested objects
            if (typeof value === 'object' && value !== null) {
              return Object.values(value)[0] || 'No answer provided';
            }
            return value;
          })
          .filter(Boolean)
          .join(", ");
      }
      
      // For simple values
      return parsed.toString();
    } catch (error) {
      // If parsing fails, return original answer as string or default message
      return answer.toString() || 'No answer provided';
    }
  };


//CSV download

const generateCSV = () => {
  if (!surveyData?.data?.questions) {
    console.error('No survey data available');
    return;
  }

  // Create CSV header
  let csvContent = 'Question,Answer\n';

  // Add each question and answer
  surveyData.data.questions.forEach((item) => {
    const question = item.questionTitle.replace(/,/g, ';'); // Replace commas with semicolons to avoid CSV issues
    const answer = formatAnswer(item.displayValue).replace(/,/g, ';');
    csvContent += `"${question}","${answer}"\n`;
  });

  // Create metadata section
  csvContent += '\nSubmission Details\n';
  csvContent += `Respondent,${user.firstName} ${user.lastName}\n`;
  csvContent += `Email,${user.email}\n`;
  csvContent += `Survey Title,${surveyData?.surveyId || 'Untitled Survey'}\n`;
  csvContent += `Submission Date,${new Date().toLocaleDateString()}\n`;

  // Create blob and download
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, `survey-results-${timestamp}.csv`);
  } else {
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute('download', `survey-results-${timestamp}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
};



  const generatePDF = () => {
    const doc = new jsPDF();
    let yPosition = 20;
    
    // Add title
    doc.setFontSize(18);
    doc.text("Survey Submission Report", 20, yPosition);
    yPosition += 15;

    // Add user information
    doc.setFontSize(12);
    doc.text(`Respondent: ${user.firstName} ${user.lastName}`, 20, yPosition);
    yPosition += 10;
    doc.text(`Email: ${user.email}`, 20, yPosition);
    yPosition += 10;
    
    yPosition += 15;

    // Add survey title
    doc.setFontSize(14);
    doc.text(surveyData?.data?.surveyTitle || 'Survey Responses', 20, yPosition);
    yPosition += 15;

    // Add responses
    doc.setFontSize(11);
    surveyData?.data?.questions?.forEach((item, index) => {
      if (yPosition > 270) {
        doc.addPage();
        yPosition = 20;
      }

      // Question
      doc.setFont(undefined, 'bold');
      const questionLines = doc.splitTextToSize(`Q${index + 1}: ${item.questionTitle}`, 170);
      doc.text(questionLines, 20, yPosition);
      yPosition += (questionLines.length * 7);

      // Answer
      doc.setFont(undefined, 'normal');
      const formattedAnswer = formatAnswer(item.displayValue);
      const answerLines = doc.splitTextToSize(formattedAnswer, 170);
      doc.text(answerLines, 20, yPosition);
      yPosition += (answerLines.length * 7) + 10;
    });

    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    doc.save(`survey-results-${timestamp}.pdf`);
  };



  // const generateZIP = async () => {
  //   if (!surveyData?.data?.questions) {
  //     console.error('No survey data available');
  //     return;
  //   }
  
  //   const zip = new JSZip();
  //   const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
  
  //   // Generate PDF buffer
  //   const doc = new jsPDF();
  //   let yPosition = 20;
    
  //   // Add title
  //   doc.setFontSize(18);
  //   doc.text("Survey Submission Report", 20, yPosition);
  //   yPosition += 15;
  
  //   // Add user information
  //   doc.setFontSize(12);
  //   doc.text(`Respondent: ${user.firstName} ${user.lastName}`, 20, yPosition);
  //   yPosition += 10;
  //   doc.text(`Email: ${user.email}`, 20, yPosition);
  //   yPosition += 25;
  
  //   // Add survey responses
  //   doc.setFontSize(11);
  //   surveyData?.data?.questions?.forEach((item, index) => {
  //     if (yPosition > 270) {
  //       doc.addPage();
  //       yPosition = 20;
  //     }
  
  //     doc.setFont(undefined, 'bold');
  //     const questionLines = doc.splitTextToSize(`Q${index + 1}: ${item.questionTitle}`, 170);
  //     doc.text(questionLines, 20, yPosition);
  //     yPosition += (questionLines.length * 7);
  
  //     doc.setFont(undefined, 'normal');
  //     const formattedAnswer = formatAnswer(item.displayValue);
  //     const answerLines = doc.splitTextToSize(formattedAnswer, 170);
  //     doc.text(answerLines, 20, yPosition);
  //     yPosition += (answerLines.length * 7) + 10;
  //   });
  
  //   // Add PDF to ZIP
  //   zip.file(`survey-results-${timestamp}.pdf`, doc.output('blob'));
  
  //   // Generate CSV content
  //   let csvContent = 'Question,Answer\n';
  //   surveyData.data.questions.forEach((item) => {
  //     const question = item.questionTitle.replace(/,/g, ';');
  //     const answer = formatAnswer(item.displayValue).replace(/,/g, ';');
  //     csvContent += `"${question}","${answer}"\n`;
  //   });
  
  //   // Add metadata to CSV
  //   csvContent += '\nSubmission Details\n';
  //   csvContent += `Respondent,${user.firstName} ${user.lastName}\n`;
  //   csvContent += `Email,${user.email}\n`;
  //   csvContent += `Survey Title,${surveyData?.surveyId || 'Untitled Survey'}\n`;
  //   csvContent += `Submission Date,${new Date().toLocaleDateString()}\n`;
  
  //   // Add CSV to ZIP
  //   zip.file(`survey-results-${timestamp}.csv`, csvContent);
  
  //   // Generate and download ZIP
  //   const zipBlob = await zip.generateAsync({ type: 'blob' });
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(zipBlob);
  //   link.download = `survey-results-${timestamp}.zip`;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   URL.revokeObjectURL(link.href);
  // };



  const generateZIP = async () => {
    if (!surveyData?.data?.questions) {
      console.error("No survey data available");
      return;
    }
  
    const zip = new JSZip();
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
  
    // Add generated PDF to ZIP
    const doc = new jsPDF();
    let yPosition = 20;
  
    // Add title
    doc.setFontSize(18);
    doc.text("Survey Submission Report", 20, yPosition);
    yPosition += 15;
  
    // Add user information
    doc.setFontSize(12);
    doc.text(`Respondent: ${user.firstName} ${user.lastName}`, 20, yPosition);
    yPosition += 10;
    doc.text(`Email: ${user.email}`, 20, yPosition);
    yPosition += 25;
  
    // Add survey responses
    doc.setFontSize(11);
    surveyData?.data?.questions?.forEach((item, index) => {
      if (yPosition > 270) {
        doc.addPage();
        yPosition = 20;
      }
  
      doc.setFont(undefined, "bold");
      const questionLines = doc.splitTextToSize(`Q${index + 1}: ${item.questionTitle}`, 170);
      doc.text(questionLines, 20, yPosition);
      yPosition += questionLines.length * 7;
  
      doc.setFont(undefined, "normal");
      const formattedAnswer = formatAnswer(item.displayValue);
      const answerLines = doc.splitTextToSize(formattedAnswer, 170);
      doc.text(answerLines, 20, yPosition);
      yPosition += answerLines.length * 7 + 10;
    });
  
    zip.file(`survey-results-${timestamp}.pdf`, doc.output("blob"));
  
    // Generate CSV content
    let csvContent = "Question,Answer\n";
    surveyData.data.questions.forEach((item) => {
      const question = item.questionTitle.replace(/,/g, ";");
      const answer = formatAnswer(item.displayValue).replace(/,/g, ";");
      csvContent += `"${question}","${answer}"\n`;
    });
  
    csvContent += "\nSubmission Details\n";
    csvContent += `Respondent,${user.firstName} ${user.lastName}\n`;
    csvContent += `Email,${user.email}\n`;
    csvContent += `Survey Title,${surveyData?.surveyId || "Untitled Survey"}\n`;
    csvContent += `Submission Date,${new Date().toLocaleDateString()}\n`;
  
    zip.file(`survey-results-${timestamp}.csv`, csvContent);
  
    // Fetch attachments and add them to ZIP
    const attachments = surveyData?.data?.questions?.flatMap((response) =>
      Array.isArray(response.displayValue) ? response.displayValue : [response.displayValue]
    ).filter((file) => file && typeof file === "object" && file.name && file.content);
  
    const attachmentPromises = attachments.map(async (file) => {
      try {
        const response = await fetch(`http://146.190.123.240${file.content}`);
        const blob = await response.blob();
        zip.file(file.name, blob);
      } catch (error) {
        console.error(`Failed to fetch attachment: ${file.name}`, error);
      }
    });
  
    await Promise.all(attachmentPromises);
  
    // Generate and download ZIP
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `survey-results-${timestamp}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  
  // useEffect(() => {
    
  //     fetchReports();
    
  // }, []);

  // useEffect(() => {
  //   if (surveyData) {
  //     console.log("Updated surveyData:", surveyData);
  //   }
  // }, [surveyData]);
  

  // Show loading state
  if (loading) {
    return <div className="p-8 text-center">Loading survey data...</div>;
  }

  // Show error state
  if (error) {
    return <div className="p-8 text-center text-red-600">{error}</div>;
  }


  const fetchReports = async () => {
    try {
      const response = await fetch(`http://146.190.123.240/api/reports/${survey}`);
      if (!response.ok) throw new Error('Failed to fetch reports');
      
      const data = await response.json();
      console.log(data)
      
      if (data.success) {
        setReports(data.files);
      } else {
        setReports([]);
      }
    } catch (error) {
      console.error("Error fetching reports:", error);
      setReports([]);
    }
  };


  
  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Back Navigation */}
      <div className="mb-6">
        <button
          onClick={onBack}
          className="text-blue-600 hover:text-blue-800 flex items-center gap-2 text-sm"
        >
          ← Back to Submissions
        </button>
      </div>

      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Survey Submission Details</h1>
          <p className="text-sm text-gray-600">View complete submission information and attachments</p>
        </div>
        <div className="relative">
    <button 
      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      onBlur={() => setTimeout(() => setIsDropdownOpen(false), 200)}
      className="bg-indigo-600 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 flex items-center gap-2"
    >
      <Download className="w-4 h-4" />
      Download Report
    </button>
    
    {isDropdownOpen && (
      <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div className="py-1">
          <button
            onClick={generatePDF}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
          >
            <FileText className="w-4 h-4" />
            Download PDF
          </button>
          <button
            onClick={generateCSV}
            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
          >
            <FileText className="w-4 h-4" />
            Download CSV
          </button>
          <button
        onClick={generateZIP}
        className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center gap-2"
      >
        <Archive className="w-4 h-4" />
        Download ZIP
      </button>
        </div>
      </div>
    )}
  </div>
      </div>

      {/* Tabs */}
      <div className="border-b border-gray-200 mb-6">
  <div className="flex gap-6">
    <button 
      onClick={() => setActiveTab('survey')}
      className={`pb-2 px-1 font-medium ${activeTab === 'survey' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500'}`}
    >
      Survey Details
    </button>
    <button 
      onClick={() => {setActiveTab('gap'); fetchGapAnalysisFiles();}}
      className={`pb-2 px-1 font-medium ${activeTab === 'gap' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-500'}`}
    >
      Gap Analysis
    </button>
  </div>
</div>

      {/* User Info with Progress Bar */}
      <div className="mb-6">
        <div className="flex items-center gap-3 mb-4">
          <div className="w-10 h-10 rounded-full bg-purple-100 text-purple-600 flex items-center justify-center font-medium">
            {getInitials(user.firstName, user.lastName)}
          </div>
          <div className="flex-grow">
            <div className="flex items-center gap-3">
              <h3 className="font-medium text-gray-900">{user.firstName} {user.lastName}</h3>
             
            </div>
            <p className="text-sm text-gray-500">{user.email}</p>
          </div>
          <div className="text-sm text-gray-600">
            % Complete
          </div>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-blue-600 h-2 rounded-full" 
            style={{ width: `%` }} 
          />
        </div>
      </div>

      {/* Main Content Grid */}

      {activeTab === 'survey' && ( <div>
        <div className="grid grid-cols-3 gap-8">
        {/* Survey Responses - Keeping your original data display structure */}
        <div className="col-span-2">
          <div className="border rounded-lg p-6">

          <div className="mb-6 border-b border-gray-200 pb-4">
            <h2 className="text-xl font-semibold text-gray-900">
            {surveyData?.surveyId || 'Survey Responses'}
            </h2>
          </div>
            {/* Display the data exactly as in your original code */}
            {surveyData?.data?.questions?.map((response, index) => (
            <div 
              key={index}
              className="border-b border-gray-200 last:border-0 pb-4 last:pb-0"
            >
              <p className="text-sm text-gray-600 mb-2">{response.questionTitle}</p>
              <p className="font-medium">{formatAnswer(response.displayValue)}</p>
            </div>
          ))}
            {(!surveyData?.data?.questions || surveyData.data.questions.length === 0) && (
              <p className="text-gray-500 text-center py-4">No responses recorded yet.</p>
            )}
          </div>
        </div>

        {/* Sidebar */}
        <div className="col-span-1">
          <div className="bg-white rounded-lg border p-6">
            <h2 className="text-lg font-semibold mb-4">Submission Details</h2>
            <div className="space-y-4">
              <div>
                <p className="text-sm text-gray-600">Created Date</p>
                <p className="font-medium">{new Date(surveyData?.completedAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            })}</p>
              </div>
              {/* <div>
                <p className="text-sm text-gray-600">Last Modified</p>
                <p className="font-medium">Feb 16, 2024 02:45 PM</p>
              </div> */}
              {/* <div>
                <p className="text-sm text-gray-600">Department</p>
                <p className="font-medium">IT Security</p>
              </div> */}
              <div>
                <p className="text-sm text-gray-600">Submission ID</p>
                <p className="font-medium">{surveyData?.submissionId}</p>
              </div>
            </div>
          </div>

          {/* <div className="mt-6 bg-white rounded-lg border p-6">
            <h2 className="text-lg font-semibold mb-4">Audit Trail</h2>
            <div className="space-y-4">
              <div>
                <p className="text-sm font-medium">Updated Section 2</p>
                <p className="text-sm text-gray-600">Feb 16, 2024 02:45 PM</p>
              </div>
              <div>
                <p className="text-sm font-medium">Added attachment</p>
                <p className="text-sm text-gray-600">Feb 15, 2024 04:20 PM</p>
              </div>
              <div>
                <p className="text-sm font-medium">Started survey</p>
                <p className="text-sm text-gray-600">Feb 15, 2024 09:30 AM</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    
    

         {/* Attachments */}
    <div className="mt-8">
  <h2 className="text-lg font-semibold mb-4">Submission Attachments</h2>
  {surveyData?.data?.questions?.flatMap((response) => {
    // Ensure displayValue is an array or convert it to an array
    const files = Array.isArray(response.displayValue) ? response.displayValue : [response.displayValue];

    // Return only valid file attachments
    return files.filter(file => file && typeof file === 'object' && file.name && file.content);
  }).map((file, index) => (
    <div key={index} className="flex items-center justify-between mb-4">
      <div className="flex items-center gap-3">
        <div className="text-gray-500">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
            />
          </svg>
        </div>
        <span className="text-sm font-medium">{file.name}</span>
      </div>
      <a
        href={`http://146.190.123.240${file.content}`}  // Ensure this points to the correct file path
        download={file.content}  // Use the uploaded file name for download
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-800"
      >
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
      </a>
    </div>
  ))}

  {!surveyData?.data?.questions?.some(
    (response) => Array.isArray(response.displayValue) && response.displayValue.length > 0
  ) && (
    <p className="text-gray-500 text-center py-4">No attachments uploaded.</p>
  )}
</div></div>

    )}




       {/* gap Analysis */}

      {activeTab === 'gap' && (
        <div>
  <div className="grid grid-cols-3 gap-8">
    {/* Gap Analysis Documents */}
    <div className="col-span-2">
      <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
  <h3 className="text-lg font-medium text-gray-900">Gap Analysis Reports</h3>
  <label className="flex items-center px-3 py-2 bg-indigo-600 text-white text-sm rounded-lg hover:bg-indigo-700 cursor-pointer">
    <input
      type="file"
      multiple
      onChange={handleGapAnalysisUpload}
      className="hidden"
    />
    Upload Gap Reports
  </label>
</div>
        <div className="p-6">
           {latestGapAnalysisFile ? (
            <div className="border rounded-lg p-4 mb-6">
            
            <div className="flex items-center justify-between">
              <div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">{latestGapAnalysisFile.surveyTitle}</h3>
                <p className="text-xs text-gray-500">
                  Version: {latestGapAnalysisFile.version} • Uploaded: {new Date(latestGapAnalysisFile.uploadedAt).toLocaleString()}
                </p>
                <p className="text-xs text-gray-500">Survey: {latestGapAnalysisFile.surveyTitle}</p>
              </div>
              <a
                href={`http://146.190.123.240${latestGapAnalysisFile.url}`}
                download
                className="text-indigo-600 hover:text-indigo-900"
              >
                <Download className="w-5 h-5" />
              </a>
            </div>
          </div>
  ) : (
    <p className="text-gray-500 text-center">No Gap Analysis documents uploaded yet.</p>
  )}
</div>
      </div>
    </div>

    {/* Sidebar Info */}
    <div className="col-span-1">

      {latestGapAnalysisFile ? ( <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold mb-4">Current Document Info</h2>
        <div className="space-y-4">
          <div>
            <p className="text-sm text-gray-600">Version: {latestGapAnalysisFile.version}</p>
            <p className="font-medium">1.2</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Last Updated</p>
            <p className="font-medium">
            {new Date(latestGapAnalysisFile.uploadedAt).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            })}
</p>

          </div>
          {/* <div>
            <p className="text-sm text-gray-600">File Size</p>
            <p className="font-medium">2.4 MB</p>
          </div>
          <div>
            <p className="text-sm text-gray-600">Updated By</p>
            <p className="font-medium">John Doe</p>
          </div> */}
        </div>
      </div>) : "" }
     
    </div>
  </div>

  <div className="mt-6">
  <div className="grid grid-cols-3 gap-8">
      <div className="col-span-2">
        <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h3 className="text-lg font-medium text-gray-900">Remedy Documents</h3>
        <label className="flex items-center px-3 py-2 bg-indigo-600 text-white text-sm rounded-lg hover:bg-indigo-700 cursor-pointer">
          <input
            type="file"
            multiple
            onChange={handleReportUpload}
            className="hidden"
          />
          Upload Remedy Reports
        </label>
      </div>
      <div className="p-6">
            {reports.length > 0 ? (
              <div className="space-y-4">
                {reports.map((report, index) => (
                  <div key={index} className="border rounded-lg p-4 hover:bg-gray-50 transition-colors">
                    <div className="flex items-center justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-900">{report.name}</h3>
                        <p className="text-xs text-gray-500">
                          Uploaded: {new Date(report.uploadedAt).toLocaleString()}
                        </p>
                      </div>
                      <a
                        href={`http://146.190.123.240/api/reports/download/${report._id}`}
                        download
                        className="text-indigo-600 hover:text-indigo-900 p-2 rounded-full hover:bg-indigo-50"
                      >
                        <Download className="w-5 h-5" />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-8 text-gray-500">
                <p>No remedy reports available for this survey.</p>
                <p className="text-sm mt-2">Upload new reports using the button above.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
</div>

</div>

)}
     

   

    </div>
  );
}

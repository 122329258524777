import React, { useState, useEffect } from 'react';
import SubmissionDetails from './SubmissionDetails';

import { useNavigate } from 'react-router-dom';

export default function DashboardHome({overallProgress}) {
  const [userCount, setUserCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [incompleteCount, setIncompleteCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [gapAnalysisCount, setGapAnalysisCount] = useState(0);
  const [completedSurveys, setCompletedSurveys] = useState([]);
  const [inProgressSurveys, setInProgressSurveys] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
const [selectedSurvey, setSelectedSurvey] = useState(null);
const [showSubmissionDetails, setShowSubmissionDetails] = useState(false);


  



  useEffect(() => {
    fetch('http://146.190.123.240/api/users/count')
      .then((res) => res.json())
      .then((data) => {
        setUserCount(data.total);
        
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error:', err);
        setError('Error fetching user data');
        setLoading(false);
      });

      

      // const fetchUsersWithResults = async () => {
      //   try {
      //     const response = await fetch("http://146.190.123.240/api/admin/users-with-survey-results");
    
      //     if (!response.ok) {
      //       throw new Error(`HTTP error! Status: ${response.status}`);
      //     }
    
      //     const data = await response.json();
          
      //     console.log(data)

      //     if (data.success) {
      //       setAllUsers(data.data);
      //     } else {
      //       setError(data.message || "Failed to fetch users with survey results");
      //     }
      //   } catch (err) {
      //     setError("Error fetching users with survey results. Please try again later.");
      //     console.error("Fetch Error:", err);
      //   } finally {
      //     setLoading(false);
      //   }
      // };
    
      // fetchUsersWithResults();


      fetch('http://146.190.123.240/api/survey/incomplete-count')
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setIncompleteCount(data.count);
          console.log("Survey Progress Data:", data);
          console.log("Incomplete Count:", data.count);
          console.log("Full Survey Progress Collection:", data.surveyProgressData);
          
          // Set the in-progress surveys data if available
          if (data.surveyProgressData) {
            const inProgress = data.surveyProgressData.map(survey => ({
              ...survey,
              progress: Math.round((survey.answeredQuestions / survey.totalQuestions) * 100) || 0
            }));
            setInProgressSurveys(inProgress);
          }
        }
      })
      .catch((err) => {
        console.error('Error fetching incomplete count:', err);
      });


      // fetch('http://146.190.123.240/api/gap-analysis/count')
      // .then(res => res.json())
      // .then(data => {
      //   console.log("Gap Analysis Response:", data);
      //   if (data.success) {
      //     setGapAnalysisCount(data.total); // Changed from data.count to data.total
          
      //     // If you need to store the files as well
      //     if (data.files) {
      //       // Set files state if you need it
      //       // setGapAnalysisFiles(data.files);
      //     }
      //   } else {
      //     console.warn("Gap analysis count fetch returned success: false");
      //     setGapAnalysisCount(0);
      //   }
      // })
      // .catch(err => {
      //   console.error('Error fetching gap analysis count:', err);
      //   setGapAnalysisCount(0);
      // });


   //  


   fetch('http://146.190.123.240/api/admin/users-with-survey-results')
   .then((res) => res.json())
   .then((data) => {
     if (data.success) {
       // Count total number of surveys across all users
       const totalCompletedSurveys = data.data.reduce((total, user) => {
         return total + (user.surveys ? user.surveys.length : 0);
       }, 0);
       setCompletedCount(totalCompletedSurveys);

       // Process completed surveys for display
       const allCompletedSurveys = data.data.reduce((surveys, user) => {
         const userSurveys = user.surveys ? user.surveys.map(survey => ({
           ...survey,
           userDetails: {
             firstName: user.firstName,
             lastName: user.lastName,
             email: user.email
           }
         })) : [];
         return [...surveys, ...userSurveys];
       }, []);

       setCompletedSurveys(allCompletedSurveys);
       
       console.log("all survey are",allCompletedSurveys)

       
     }
   })
   .catch((err) => {
     console.error('Error fetching completed surveys:', err);
   });


   const fetchGapAnalysisData = async () => {
    try {
      console.log('Fetching gap analysis data...');
      const response = await fetch('http://146.190.123.240/api/gap-analysis/count');

      console.log(response)
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Gap Analysis Response:", data);

      if (data.success) {
        // Check for both total and files length as fallback
        const count = data.total ?? data.files?.length ?? 0;
        console.log("Setting gap analysis count to:", count);
        setGapAnalysisCount(count);
      } else {
        console.warn("Gap analysis API returned success: false");
        setGapAnalysisCount(0);
      }
    } catch (error) {
      console.error('Error fetching gap analysis data:', error);
      setGapAnalysisCount(0);
    }
  };


      const fetchUsers = async () => {
        try {
          const response = await fetch('http://146.190.123.240/api/users');
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data = await response.json();
  
          if (data.success) {
            setUsers(data.data);
          } else {
            setError(data.message || 'Failed to fetch users');
          }
        } catch (err) {
          setError('Error fetching users. Please try again later.');
          console.error('Fetch Error:', err);
        } finally {
          setLoading(false);
        }
      };
      fetchGapAnalysisData()
      fetchUsers();
      
  }, []);





  function getInitials(firstname, lastname) {
    if (!firstname && !lastname) return '';
    const firstInitial = firstname?.trim()?.[0]?.toUpperCase() || '';
    const lastInitial = lastname?.trim()?.[0]?.toUpperCase() || '';
    return firstInitial + lastInitial;
  }


  const handleViewDetails = (user, survey) => {

    console.log(user,survey)
    setSelectedUser(user);
    setSelectedSurvey(survey);
    setShowSubmissionDetails(true);
  };
  
  const handleBackToList = () => {
    setShowSubmissionDetails(false);
    setSelectedUser(null);
    setSelectedSurvey(null);
  };



  if (showSubmissionDetails && selectedUser && selectedSurvey) {
    return (
      <SubmissionDetails 
        user={selectedUser}
        survey={selectedSurvey}
        onBack={handleBackToList}
      />
    );
  }
  

  return (
    <div className="min-h-screen bg-gray-50">
     

      {/* Main Content */}
      <main className="container mx-auto p-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-2">Dashboard</h1>
        <p className="text-sm text-gray-600 mb-8">Data Ink Compliance Status Overview</p>

        {/* Metrics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
          <MetricCard title="Total Users" value={userCount.toString()} icon={<UserIcon />} color="blue" />
          <MetricCard title="In Progress"  value={incompleteCount.toString()} subtitle="Surveys in draft" icon={<ClockIcon />} color="yellow" />
          <MetricCard title="Completed Surveys" value={completedCount.toString()} subtitle="Awaiting gap analysis" icon={<CheckIcon />} color="green" />
          {/* <MetricCard title="Gap Analysis Complete" value="32" icon={<ChartIcon />} color="blue" /> */}
        </div>

        {loading && <p>Loading data...</p>}
        {error && <p className="text-red-500">{error}</p>}

        {/* In Progress Surveys Table */}
        <div className="bg-white rounded-lg shadow mb-8">
          <div className="px-6 py-4 border-b">
            <h2 className="text-lg font-semibold text-gray-900">In Progress Surveys</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                  <th className="px-6 py-3">USER</th>
                  <th className="px-6 py-3">PROGRESS</th>
                
                  <th className="px-6 py-3">MODULE</th>

                  <th className="px-6 py-3">LAST UPDATED</th>
                  {/* <th className="px-6 py-3">ACTIONS</th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
  {inProgressSurveys.map((survey, index) => {
    const user = users.find(u => u._id === survey.userId) || {};
    const progressPercentage = Math.round((survey.answeredQuestions / survey.totalQuestions) * 100) || 0;
    
    return (
      <ProgressRow
        key={index}
        initials={getInitials(user.firstName, user.lastName)}
        name={`${user.firstName} ${user.lastName}`}
        module={survey.surveyId}
        email={user.email || 'N/A'}
        progress={progressPercentage}
        lastUpdated={survey.lastUpdated ? new Date(survey.lastUpdated).toLocaleDateString() : 'N/A'}
        onViewDetails={() => handleViewDetails(user, survey)}
      />
    );
  })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Complete Submissions Table */}
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-4 border-b">
            <h2 className="text-lg font-semibold text-gray-900">Complete Submissions</h2>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider bg-gray-50">
                  <th className="px-6 py-3">USER</th>
                  <th className="px-6 py-3">MODULE</th>
                  <th className="px-6 py-3">STATUS</th>
                  <th className="px-6 py-3">SUBMITTED DATE</th>
                  {/* <th className="px-6 py-3">GAP ANALYSIS</th> */}
                  <th className="px-6 py-3">ACTIONS</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {completedSurveys.map((survey, index) => {
                  const fullName = `${survey.userDetails.firstName} ${survey.userDetails.lastName}`;
                  const initials = getInitials(survey.userDetails.firstName, survey.userDetails.lastName);
                  const hasGapAnalysis = survey.gapAnalysis && survey.gapAnalysis.length > 0;

                  
                  return (
                    <CompletedRow
                      key={index}
                      initials={initials}
                      name={fullName}
                      department={survey?.userDetails?.email || 'N/A'}
                      module={survey.surveyId}
                      
                      date={new Date(survey.completedAt).toLocaleDateString()}
                      status="Complete"
                      gapAnalysis={hasGapAnalysis ? "Report Available" : "Pending Analysis"}
                      onViewDetails={() => handleViewDetails(survey.userDetails, survey._id)}
                    />
                  );
                })}
                {completedSurveys.length === 0 && (
                  <tr>
                    <td colSpan="5" className="px-6 py-4 text-center text-gray-500">
                      No completed surveys found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  );
}

function MetricCard({ title, value, subtitle, icon, color }) {
  const colors = {
    blue: 'bg-blue-100 text-blue-600',
    yellow: 'bg-yellow-100 text-yellow-600',
    green: 'bg-green-100 text-green-600',
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <div className="flex items-center justify-between mb-4">
        <div className={`w-12 h-12 ${colors[color]} rounded-full flex items-center justify-center`}>{icon}</div>
        <span className="text-3xl font-bold text-gray-900">{value}</span>
      </div>
      <h3 className="text-sm font-medium text-gray-900">{title}</h3>
      {subtitle && <p className="text-xs text-gray-500 mt-1">{subtitle}</p>}
    </div>
  );
}

function ProgressRow({ initials, name, email, module, department, progress, lastUpdated , onViewDetails}) {
  
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">
            {initials}
          </div>
          <div className="ml-3">
            <div className="font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-500">{email}</div>
          </div>
        </div>
      </td>
      
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="w-full bg-gray-200 rounded-full h-2.5">
          <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
        </div>
        <span className="text-sm text-gray-500">{progress}% Complete</span>
      </td>
      <td className="font-medium text-gray-900">{module}</td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{lastUpdated}</td>
      {/* <td className="px-6 py-4 whitespace-nowrap">
        <button className="text-blue-600 hover:text-blue-800" onClick={onViewDetails} >View Details</button>
      </td> */}
    </tr>
  );
}

function CompletedRow({ initials, name, module, department, date, status, gapAnalysis,onViewDetails }) {

  const navigate = useNavigate();
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="w-8 h-8 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center text-sm font-medium">
            {initials}
          </div>
          <div className="ml-3">
            <div className="font-medium text-gray-900">{name}</div>
            <div className="text-sm text-gray-500">{department}</div>
          </div>
        </div>
      </td>


      <td className="font-medium text-gray-900">{module}</td>
      <td className="px-6 py-4 whitespace-nowrap">
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {status}
        </span>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{date}</td>
      {/* <td className="px-6 py-4 whitespace-nowrap">
        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
          gapAnalysis === 'Report Available' 
            ? 'bg-blue-100 text-blue-800'
            : 'bg-yellow-100 text-yellow-800'
        }`}>
          {gapAnalysis}
        </span>
      </td> */}
      <td className="px-6 py-4 whitespace-nowrap">
        <button className="text-blue-600 hover:text-blue-800 mr-4" onClick={onViewDetails}>View Survey</button>
        {gapAnalysis === 'Report Available' && (
          <button className="text-blue-600 hover:text-blue-800">View Report</button>
        )}
      </td>
    </tr>
  );
}

function UserIcon() {
  return (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
    </svg>
  );
}

function ClockIcon() {
  return (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

function CheckIcon() {
  return (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
}

function ChartIcon() {
  return (
    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
    </svg>
  );
}

